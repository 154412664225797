<template>
  <div class="login-wrap flex-column-center-container">
    <div class="login-content flex-start-container">
      <div class="flex-column-container">
        <!--<img class="login-i" src="../assets/login-icon.png" alt="" />
        <img class="ms-smc-bg" src="../assets/login-d.png" alt="" />-->
      </div>
      <div class="ms-login" :title="`${$store.state.title}管理系统`">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="login-form">
          <el-form-item prop="username">
            <el-input class="rule-form-input-login" v-model="ruleForm.username" placeholder="请输入用户名"
              @keyup.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input class="rule-form-input-login" type="password" placeholder="请输入密码" v-model="ruleForm.password"
              @keyup.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
          <div class="login-btn">
            <el-button type="primary" @click="submitForm('ruleForm')" style="font-size: 15px;">登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import global from "@/common/global";
import { testJM, testJJM, encodeUserInfo, isEmpty } from "@/common/tools";

export default {
  data: function () {
    return {
      ruleForm: {
        username: localStorage.getItem("rememberAccount")
          ? JSON.parse(testJJM(localStorage.getItem("rememberAccount"), 2))[
          "username"
          ]
          : "",
        password: localStorage.getItem("rememberAccount")
          ? JSON.parse(testJJM(localStorage.getItem("rememberAccount"), 2))[
          "password"
          ]
          : ""
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {

  },
  mounted: function () {

  },
  methods: {
    toastTip(ms) {
      this.$message({
        message: ms,
        type: "warning"
      });
    },
    reqHandle(res) {
      var that = this;
      if (res.code == 0) {
        if (that.timeCalc) clearTimeout(that.timeCalc);
        var resData = res.data;
        var userInfo = Object.assign(resData, {
          brandCodes: resData.branches_brand_priv
        });
        sessionStorage.setItem("sm_oa_auth", res.data.token);
        sessionStorage.setItem("userInfo", encodeUserInfo(userInfo));
        sessionStorage.setItem(
          "ms_username",
          resData.nickName ? resData.nickName : resData.phonenumber
        );
        sessionStorage.setItem("sm_timeout_status", Date.now());
        this.$router.push("/");
      } else {
        this.toastTip(res.message);
      }
    },
    submitForm(formName) {
      localStorage.setItem(
        "rememberAccount",
        testJM(JSON.stringify(this.ruleForm), 2)
      );
      this.$refs[formName].validate(valid => {
        if (valid) {
          var data = {
            userName: this.ruleForm.username,
            password: this.encrypted(this.ruleForm.password),
            loginType: 1,
            branches: 0
          };
          // if (
          //   (data.userName == "fenghua" || data.userName == "216584") &&
          //   location.host.indexOf("oms.aixunmiao.com") == 0
          // ) {
          //   location.href = "http://fenghua.aixunmiao.com";
          // }
          this.$axios
            .post("/oa/login", this.$qs.stringify(data))
            .then(res => {
              console.log(res.data);
              this.reqHandle(res.data);
            })
            .catch(function (error) {
              //console.log(error);
            });
        } else {
          this.toastTip("请先通过验证");
          return false;
        }
      });
    }
  },
  destroyed() {

  }
};
</script>

<style scoped lang="less">
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-size: 12px;
  background-color: #B7BDFB;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36%;
    height: 130%;
    background-image: radial-gradient(ellipse farthest-side at 0% 0%, #9ADEDF 60%, #B7BDFB 98%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 36%;
    height: 130%;
    background-image: radial-gradient(ellipse farthest-side at 100% 0%, #D3D3D3 1%, #F9CCCF 20%, #B7BDFB 98%);
    border-bottom-left-radius: 100%;
  }
}

.login-content {
  position: relative;
  z-index: 6;
  // width: 785px;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  padding: 30px 76px 78px 14px;

  .login-i {
    width: 143px;
    margin-bottom: 71px;
  }

  .ms-smc-bg {
    width: 321px;
  }

  .ms-login {
    width: 320px;
    background-color: white;
    border-radius: 13px;
    overflow: hidden;
    margin-left: 55px;
    margin-top: 32px;

    &::before {
      content: attr(title);
      display: block;
      background-color: #5873D8;
      line-height: 55px;
      height: 55px;
      color: white;
      font-size: 18px;
    }

    .login-form {
      padding-top: 30px;
      padding-bottom: 55px;

      .el-form-item {
        width: 260px;
        margin: 0 auto 20px;
      }

      .login-btn {
        transform: translate(0, 10px);

        .el-button {
          width: 260px;
          height: 40px;
          border-radius: 20px;
          font-size: 15px;
        }
      }
    }
  }

}

@media (min-width: 1900px) {
  .login-content {
    transform: scale(1.4);
  }
}
</style>